import { FuncionalidadesMap } from './FuncionalidadesMap';
import TipoUsuario from './TipoUsuario';
import TipoVinculo from './TipoVinculo';
export default class Rbac {
  constructor(permissoesRBAC, perfisRBAC) {
    this.permissoes = permissoesRBAC;
    this.perfis = perfisRBAC;
  }
  montarAcesso(funcionalidade) {
    return this.montarAcessoPeloPerfis(funcionalidade);
  }
  temPermissao(funcionalidade) {
    const acesso = this.montarAcesso(funcionalidade);
    return acesso.temPermissao || false;
  }
  montarAcessoPeloPerfis(funcionalidade) {
    var _a, _b, _c, _d, _e;
    let acesso = {
      temPermissao: false,
      tipoUsuario: TipoUsuario.USUARIO_SEM_VINCULO,
      tipoVinculo: TipoVinculo.USUARIO,
      idsEstado: [],
      idsInstituicao: [],
      idsCidade: [],
      estados: [],
      idsRedeTeleconsultoria: []
    };
    // Caso a funcionalidade não exista. Retornar o acesso padrão.
    const funcionalidadeObj = FuncionalidadesMap[funcionalidade];
    if (!funcionalidadeObj) {
      return acesso;
    }
    // 1 - Verificar se é um perfil ADMIN, não precisa concatenar os vínculos, pois tem acesso a tudo. FIND
    const perfilAdm = (_a = this.perfis) === null || _a === void 0 ? void 0 : _a.find(e => {
      var _a, _b;
      return e.vinculo === 'G' && (
      // Verificar se é um perfil ADMIN
      (_a = funcionalidadeObj.tipoVinculo) === null || _a === void 0 ? void 0 : _a.includes(e.vinculo)) && (
      // Verificar se o tipo de vínculo do perfil está na funcionalidade
      (_b = e.permissoes) === null || _b === void 0 ? void 0 : _b.find(ei => {
        var _a;
        return (_a = funcionalidadeObj.permissoes) === null || _a === void 0 ? void 0 : _a.includes(ei);
      }));
    });
    if (perfilAdm) return Object.assign(Object.assign({}, acesso), {
      temPermissao: true,
      tipoUsuario: TipoUsuario.ADMIN,
      tipoVinculo: TipoVinculo.ADMIN
    });
    // 2 - Perfis do tipo rede de teleconsultoria. Precisa concatenar os vínculos. FILTER.
    const perfisNivelRedeTeleconsultoria = ((_b = this.perfis) === null || _b === void 0 ? void 0 : _b.filter(e => {
      var _a, _b;
      return e.vinculo === 'D' && ((_a = funcionalidadeObj.tipoVinculo) === null || _a === void 0 ? void 0 : _a.includes(e.vinculo)) && ((_b = e.permissoes) === null || _b === void 0 ? void 0 : _b.find(ei => {
        var _a;
        return (_a = funcionalidadeObj.permissoes) === null || _a === void 0 ? void 0 : _a.includes(ei);
      }));
    })) || [];
    const arrayIdsRedeTeleconsultoria = perfisNivelRedeTeleconsultoria.map(perfil => perfil.redesTeleconsultoria.map(redeTeleconsultoria => redeTeleconsultoria.id));
    if (arrayIdsRedeTeleconsultoria.length > 0) {
      let idsRedeTeleconsultoria = arrayIdsRedeTeleconsultoria.reduce((a, acc) => a.concat(acc));
      idsRedeTeleconsultoria = Array.from(new Set(idsRedeTeleconsultoria));
      acesso = Object.assign(Object.assign({}, acesso), {
        temPermissao: true,
        tipoUsuario: TipoUsuario.USUARIO_COM_VINCULO,
        tipoVinculo: TipoVinculo.REDE_TELECONSULTORIA,
        idsRedeTeleconsultoria
      });
      return acesso;
    }
    // 3 - Perfis do tipo estado. Precisa concatenar os vínculos. FILTER.
    const perfisNivelEstado = ((_c = this.perfis) === null || _c === void 0 ? void 0 : _c.filter(e => {
      var _a, _b;
      return e.vinculo === 'E' && ((_a = funcionalidadeObj.tipoVinculo) === null || _a === void 0 ? void 0 : _a.includes(e.vinculo)) && ((_b = e.permissoes) === null || _b === void 0 ? void 0 : _b.find(ei => {
        var _a;
        return (_a = funcionalidadeObj.permissoes) === null || _a === void 0 ? void 0 : _a.includes(ei);
      }));
    })) || [];
    const arrayIdsEstados = perfisNivelEstado.map(perfil => perfil.estados.map(estado => estado.id));
    const arrayEstados = perfisNivelEstado.map(perfil => perfil.estados.map(estado => estado.sigla));
    if (arrayIdsEstados.length > 0) {
      let idsEstado = arrayIdsEstados.reduce((a, acc) => a.concat(acc));
      idsEstado = Array.from(new Set(idsEstado));
      let estados = arrayEstados.reduce((a, acc) => a.concat(acc));
      estados = Array.from(new Set(estados));
      acesso = Object.assign(Object.assign({}, acesso), {
        temPermissao: true,
        tipoUsuario: TipoUsuario.USUARIO_COM_VINCULO,
        tipoVinculo: TipoVinculo.ESTADO,
        estados,
        idsEstado
      });
      return acesso;
    }
    // 4 - Perfis do tipo municipio. Precisa concatenar os vínculos. FILTER.
    const perfisNivelMunicipio = ((_d = this.perfis) === null || _d === void 0 ? void 0 : _d.filter(e => {
      var _a, _b;
      return e.vinculo === 'M' && ((_a = funcionalidadeObj.tipoVinculo) === null || _a === void 0 ? void 0 : _a.includes(e.vinculo)) && ((_b = e.permissoes) === null || _b === void 0 ? void 0 : _b.find(ei => {
        var _a;
        return (_a = funcionalidadeObj.permissoes) === null || _a === void 0 ? void 0 : _a.includes(ei);
      }));
    })) || [];
    const arrayIdsCidades = perfisNivelMunicipio.map(perfil => perfil.municipios.map(municipio => municipio.id));
    if (arrayIdsCidades.length > 0) {
      let idsCidade = arrayIdsCidades.reduce((a, acc) => a.concat(acc));
      idsCidade = Array.from(new Set(idsCidade));
      acesso = Object.assign(Object.assign({}, acesso), {
        temPermissao: true,
        tipoUsuario: TipoUsuario.USUARIO_COM_VINCULO,
        tipoVinculo: TipoVinculo.MUNICIPIO,
        idsCidade
      });
      return acesso;
    }
    // 5 - Perfis do tipo instituição. Precisa concatenar os vínculos. FILTER.
    const perfisNivelInstituicoes = ((_e = this.perfis) === null || _e === void 0 ? void 0 : _e.filter(e => {
      var _a, _b;
      return e.vinculo === 'I' && ((_a = funcionalidadeObj.tipoVinculo) === null || _a === void 0 ? void 0 : _a.includes(e.vinculo)) && ((_b = e.permissoes) === null || _b === void 0 ? void 0 : _b.find(ei => {
        var _a;
        return (_a = funcionalidadeObj.permissoes) === null || _a === void 0 ? void 0 : _a.includes(ei);
      }));
    })) || [];
    const arrayIdsInstituicoes = perfisNivelInstituicoes.map(perfil => perfil.instituicoes.map(instituicao => instituicao.id));
    if (arrayIdsInstituicoes.length > 0) {
      let idsInstituicao = arrayIdsInstituicoes.reduce((a, acc) => a.concat(acc));
      idsInstituicao = Array.from(new Set(idsInstituicao));
      acesso = Object.assign(Object.assign({}, acesso), {
        temPermissao: true,
        tipoUsuario: TipoUsuario.USUARIO_COM_VINCULO,
        tipoVinculo: TipoVinculo.INSTITUICAO,
        idsInstituicao
      });
      return acesso;
    }
    return acesso;
  }
  acessarDadosSync() {
    var _a, _b, _c, _d, _e;
    // 1 - Verificar se é um perfil ADMIN
    const perfisAdm = ((_a = this.perfis) === null || _a === void 0 ? void 0 : _a.filter(e => {
      return e.vinculo === 'G';
    })) || [];
    const isAdmin = perfisAdm.length > 0;
    if (isAdmin) return {
      temPermissao: true,
      tipoUsuario: TipoUsuario.ADMIN,
      tipoVinculo: TipoVinculo.ADMIN
    };
    // 2 - Perfis do tipo municipio
    const perfisNivelMunicipio = ((_b = this.perfis) === null || _b === void 0 ? void 0 : _b.filter(e => {
      return e.vinculo === 'M';
    })) || [];
    const arrayIdsCidades = perfisNivelMunicipio.map(perfil => perfil.municipios.map(municipio => municipio.id));
    // 3 - Perfis do tipo estado
    const perfisNivelEstado = ((_c = this.perfis) === null || _c === void 0 ? void 0 : _c.filter(e => {
      return e.vinculo === 'E';
    })) || [];
    const arrayIdsEstados = perfisNivelEstado.map(perfil => perfil.estados.map(estado => estado.id));
    const arrayEstados = perfisNivelEstado.map(perfil => perfil.estados.map(estado => estado.sigla));
    // 4 - Perfis do tipo rede de teleconsultoria
    const perfisNivelRedeTeleconsultoria = ((_d = this.perfis) === null || _d === void 0 ? void 0 : _d.filter(e => {
      return e.vinculo === 'D';
    })) || [];
    const arrayIdsRedeTeleconsultoria = perfisNivelRedeTeleconsultoria.map(perfil => perfil.redesTeleconsultoria.map(redeTeleconsultoria => redeTeleconsultoria.id));
    // 5 - Perfis do tipo instituição
    const perfisNivelInstituicoes = ((_e = this.perfis) === null || _e === void 0 ? void 0 : _e.filter(e => {
      return e.vinculo === 'I';
    })) || [];
    const arrayIdsInstituicoes = perfisNivelInstituicoes.map(perfil => perfil.instituicoes.map(instituicao => instituicao.id));
    let acesso = {
      temPermissao: false,
      tipoUsuario: TipoUsuario.USUARIO_SEM_VINCULO,
      tipoVinculo: TipoVinculo.USUARIO,
      idsEstado: [],
      idsInstituicao: [],
      idsCidade: [],
      estados: [],
      idsRedeTeleconsultoria: []
    };
    if (arrayIdsCidades.length > 0) {
      let idsCidade = arrayIdsCidades.reduce((a, acc) => a.concat(acc));
      idsCidade = Array.from(new Set(idsCidade));
      acesso = Object.assign(Object.assign({}, acesso), {
        tipoUsuario: TipoUsuario.USUARIO_COM_VINCULO,
        tipoVinculo: TipoVinculo.MUNICIPIO,
        idsCidade
      });
    }
    if (arrayIdsInstituicoes.length > 0) {
      let idsInstituicao = arrayIdsInstituicoes.reduce((a, acc) => a.concat(acc));
      idsInstituicao = Array.from(new Set(idsInstituicao));
      acesso = Object.assign(Object.assign({}, acesso), {
        tipoUsuario: TipoUsuario.USUARIO_COM_VINCULO,
        idsInstituicao
      });
    }
    if (arrayIdsEstados.length > 0) {
      let idsEstado = arrayIdsEstados.reduce((a, acc) => a.concat(acc));
      idsEstado = Array.from(new Set(idsEstado));
      let estados = arrayEstados.reduce((a, acc) => a.concat(acc));
      estados = Array.from(new Set(estados));
      acesso = Object.assign(Object.assign({}, acesso), {
        tipoUsuario: TipoUsuario.USUARIO_COM_VINCULO,
        estados,
        idsEstado
      });
    }
    if (arrayIdsRedeTeleconsultoria.length > 0) {
      let idsRedeTeleconsultoria = arrayIdsRedeTeleconsultoria.reduce((a, acc) => a.concat(acc));
      idsRedeTeleconsultoria = Array.from(new Set(idsRedeTeleconsultoria));
      acesso = Object.assign(Object.assign({}, acesso), {
        tipoUsuario: TipoUsuario.USUARIO_COM_VINCULO,
        idsRedeTeleconsultoria
      });
    }
    return acesso;
  }
  /**
   * Regras para acesso a dados do sistema.
   */
  acessarDados() {
    return new Promise((resolve, reject) => {
      try {
        return resolve(this.acessarDadosSync());
      } catch (error) {
        return reject(error);
      }
    });
  }
}