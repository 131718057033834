import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import classnames from 'classnames';

// components
import Header from '../../components/Header/Header';

// styles
import useStyles from '../../themes/styles';
import SystemConstants from '../../util/SystemConstants';

import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import MsgAlert from '../../components/Alerts/MsgAlert';
import { ErrorContent } from '../error/Error';

// Formulários globais
import NaoAutorizado from '../error/NaoAutorizado';

/* @useSignals */
import { usuarioSignal } from 'src/signals/usuario';
import { alertaSignal, setOpenAlert } from 'src/signals/alertaSignal';

const Interno = () => {
    const classes = useStyles();
    const pages = [SystemConstants.HOME, ...usuarioSignal.value.submodulos];

    return (
        <div className={classes.root}>
            <>
                <Header />

                <Routes>
                    {pages.map((submodulo) => {
                        let contentClass;
                        switch (submodulo.layoutType) {
                            case 'fullscreen':
                                contentClass = classnames(classes.mainContentFullscreen);
                                break;
                            case 'sem-tab':
                                contentClass = classnames(classes.mainContentSemTab);
                                break;
                            case 'padrao':
                            default:
                                contentClass = classnames(classes.mainContentPadrao);
                                break;
                        }

                        return (
                            <Route
                                key={submodulo.id}
                                path={submodulo.path}
                                element={
                                    !usuarioSignal.value.isAuthorized ? (
                                        <Navigate replace to="/apps/nao-autorizado" />
                                    ) : (
                                        <div className={contentClass}>{React.createElement(SystemConstants.PAGES_MAP[submodulo.path])}</div>
                                    )
                                }
                            />
                        );
                    })}
                    <Route path="/apps/nao-autorizado" element={<NaoAutorizado />} />
                    <Route path="*" element={<ErrorContent />} />
                </Routes>

                {/* Alertas Globais - @TODO Padronizar as duas formas de emitir alerta.*/}
                <ErrorAlert openAlert={alertaSignal.value.openAlert} errorCode={alertaSignal.value.errorCode} setOpenAlert={setOpenAlert} />
                <MsgAlert />
                <NotificationContainer />
            </>
        </div>
    );
};

export default Interno;
