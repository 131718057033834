import { useEffect, useState, useRef, lazy, Suspense, memo, useCallback, useContext } from 'react';
import { SttCircularProgress, SttTranslateHook } from '@stt-componentes/core';
import { SttTabs } from '@stt-componentes/core-v2';
import { makeStyles } from '@material-ui/core/styles';
import { Funcionalidade } from '@stt-analise/util';

/* @useSignals */
import { usuarioSignal } from 'src/signals/usuario';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(1.5),
        // backgroundColor: '#ddd',
        zIndex: 0,
    },
}));

// Itinerario
const ItinerarioCidadesDashboard = lazy(() => import('./ItinerarioCidadesDashboard'));
const ItinerarioExamesDashboard = lazy(() => import('./ItinerarioExamesDashboard'));
const ItinerarioPontosDashboard = lazy(() => import('./ItinerarioPontosDashboard'));
const ItinerarioSolicitacoesDashboard = lazy(() => import('./ItinerarioSolicitacoesDashboard'));
// Teleconsultorias
const TeleconsultoriaDashboard = lazy(() => import('./TeleconsultoriaDashboard'));
// Alofono
const AlofonoDashboard = lazy(() => import('./AlofonoDashboard'));
const AlofonoDashboardIndividual = lazy(() => import('./AlofonoDashboardIndividual'));

const ItinerarioCidadesDashboardSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <ItinerarioCidadesDashboard {...props} />
        </Suspense>
    );
});

const ItinerarioExamesDashboardSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <ItinerarioExamesDashboard {...props} />
        </Suspense>
    );
});

const ItinerarioPontosDashboardSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <ItinerarioPontosDashboard {...props} />
        </Suspense>
    );
});

const ItinerarioSolicitacoesDashboardSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <ItinerarioSolicitacoesDashboard {...props} />
        </Suspense>
    );
});

const TeleconsultoriaDashboardSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <TeleconsultoriaDashboard {...props} />
        </Suspense>
    );
});

const AlofonoDashboardSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <AlofonoDashboard {...props} />
        </Suspense>
    );
});

const AlofonoDashboardIndividualSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <AlofonoDashboardIndividual {...props} />
        </Suspense>
    );
});


const MapaInterativoPage = ({ user }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [abas, setAbas] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState('0');
    const stateRef = useRef();

    stateRef.current = abas;

    const adicionarAba = useCallback((aba) => {
        let dadosAbas = [...stateRef.current];

        let { idAba } = aba;
        // !some para evitar adição de aba repetida
        if (!dadosAbas.some((a) => a.idAba === idAba)) {
            dadosAbas.push(aba);
            setAbas(dadosAbas);
        }

        setTimeout(() => {
            setAbaAtiva(dadosAbas.findIndex((a) => a.idAba === idAba).toString());
        }, 120);
    }, []);

    const removerAba = (indice) => {
        setAbaAtiva('0');
        const i = indice[0];

        const dadosAbas = [...stateRef.current.slice(0, i), ...stateRef.current.slice(i + 1)];

        setTimeout(() => {
            setAbas(dadosAbas);
        }, 120);
    };

    useEffect(() => {
        let dadosAbas = [];
        if (usuarioSignal.value.rbac.temPermissao(Funcionalidade.PAGINA_DASHBOARD_TELECONSULTORIA)) {
            dadosAbas = [
                ...dadosAbas,
                {
                    titulo: strings.PaginaInicial.dashboardTeleconsultoria,
                    conteudo: TeleconsultoriaDashboardSuspense,
                    key: 'teleconsultorias',
                    permanente: true,
                    adicionarAba,
                },
            ];
        }
        if (usuarioSignal.value.rbac.temPermissao(Funcionalidade.PAGINA_DASHBOARD_IT)) {
            dadosAbas = [
                ...dadosAbas,
                {
                    titulo: `Itinerário - ${strings.PaginaInicial.dashboardItinerarioCidades}`,
                    conteudo: ItinerarioCidadesDashboardSuspense,
                    key: 'Cidades',
                    permanente: true,
                    adicionarAba,
                },
                {
                    titulo: `Itinerário - ${strings.PaginaInicial.dashboardItinerarioExames}`,
                    conteudo: ItinerarioExamesDashboardSuspense,
                    key: 'Exames',
                    permanente: true,
                    adicionarAba,
                },
                {
                    titulo: `Itinerário - ${strings.PaginaInicial.dashboardItinerarioPontos}`,
                    conteudo: ItinerarioPontosDashboardSuspense,
                    key: 'Pontos',
                    permanente: true,
                    adicionarAba,
                },
                {
                    titulo: `Itinerário - ${strings.PaginaInicial.dashboardItinerarioSolicitacoes}`,
                    conteudo: ItinerarioSolicitacoesDashboardSuspense,
                    key: 'Solicitações',
                    permanente: true,
                    adicionarAba,
                },
            ];
        }
        if (usuarioSignal.value.rbac.temPermissao(Funcionalidade.PAGINA_DASHBOARD_ALOFONO)) {
            dadosAbas = [
                ...dadosAbas,
                {
                    titulo: strings.PaginaInicial.dashboardAlofono,
                    conteudo: AlofonoDashboardSuspense,
                    key: 'Alofono',
                    permanente: true,
                    adicionarAba,
                },
                {
                    titulo: strings.PaginaInicial.dashboardAlofonoIndividual,
                    conteudo: AlofonoDashboardIndividualSuspense,
                    key: 'AlofonoIndividual',
                    permanente: true,
                    adicionarAba,
                },
            ];
        }

        setAbas(dadosAbas);
    }, [user]);

    return (
        <SttTabs
            abas={abas}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={setAbaAtiva}
            abaAtiva={abaAtiva}
            handleCloseTab={(indice) => removerAba(indice)}
            permanente={true}
            preload={false}
        />
    );
};

export default MapaInterativoPage;
