import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { tableToJson } from './HTMLStringUtil';
import PDFUtil from './PDFUtil';
const gerarDocJSPDF = function (columns, rows, estilo) {
  let frontend = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (!columns || columns.length === 0 || !rows || rows.length === 0) {
    console.error('Tabela não encontrada para exportação.');
    return;
  }
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: 'a4'
  });
  const totalPagesExp = '{total_pages_count_string}';
  doc.setFont('helvetica');
  doc.setFontSize(9);
  doc.setDrawColor(50, 54, 57);
  const larguraPagina = doc.internal.pageSize.getWidth();
  const alturaPagina = doc.internal.pageSize.getHeight();
  const margem = 30;
  const footerHeight = 35;
  const larguraUtil = larguraPagina - margem * 2;
  const colunasPorPagina = 6;
  const numeroDePaginas = Math.ceil(columns.length / colunasPorPagina);
  for (let paginaAtual = 0; paginaAtual < numeroDePaginas; paginaAtual++) {
    if (paginaAtual > 0) {
      doc.addPage(); // gera uma nova página
    }
    const startCol = paginaAtual * colunasPorPagina;
    const endCol = Math.min(startCol + colunasPorPagina, columns.length);
    const selectedColumns = columns.slice(startCol, endCol);
    const tableColumn = ['#', ...selectedColumns];
    const tableRows = rows.map((row, index) => [index + 1, ...selectedColumns.map((_, j) => row[j] ? row[j] : '')]);
    const estiloPadrao = {
      startY: 150,
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 5,
        font: 'helvetica',
        valign: 'middle',
        halign: 'left',
        overflow: 'linebreak',
        minCellHeight: 10,
        textColor: [51, 51, 51]
      },
      headStyles: {
        fillColor: [153, 147, 153],
        textColor: [255, 255, 255],
        fontStyle: 'bold'
      },
      bodyStyles: {
        fillColor: [239, 239, 239],
        borderColor: [87, 86, 87]
      }
    };
    const estiloSTT = {
      startY: 150,
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 5,
        font: 'helvetica',
        valign: 'middle',
        halign: 'center',
        overflow: 'linebreak',
        minCellHeight: 10,
        textColor: [51, 51, 51]
      },
      headStyles: {
        fillColor: [10, 111, 184],
        textColor: [255, 255, 255],
        fontStyle: 'bold'
      },
      bodyStyles: {
        fillColor: [239, 239, 239],
        borderColor: [167, 165, 165]
      }
    };
    const estiloSelecionado = estilo === 'Padrão' ? estiloPadrao : estiloSTT;
    doc.autoTable(Object.assign(Object.assign({
      head: [tableColumn],
      body: tableRows
    }, estiloSelecionado), {
      columnStyles: Object.assign({
        0: {
          cellWidth: 30
        }
      }, selectedColumns.reduce((acc, _, index) => {
        acc[index + 1] = {
          cellWidth: Math.max(70, (larguraUtil - 30) / selectedColumns.length)
        }; // número total de páginas necessárias
        return acc;
      }, {})),
      margin: {
        top: 160,
        right: margem,
        bottom: footerHeight + 40,
        left: margem
      },
      didDrawPage: data => {
        if (frontend) {
          // Header Skeleton
          const blocoAltura = 70;
          doc.setFillColor('#e4e4e4');
          doc.rect(margem, 20, 80, 80, 'F');
          doc.rect(larguraPagina / 2 - 180, 20, 360, 80, 'F');
          doc.rect(larguraPagina - margem - 80, 20, 80, 80, 'F');
          doc.rect(margem, 110, larguraPagina - 2 * margem, 30, 'F');
          // Footer Skeleton (único bloco)
          const footerY = alturaPagina - footerHeight - 30; // Ajuste na posição do footer
          doc.rect(margem, footerY, larguraPagina - 2 * margem, blocoAltura, 'F');
          doc.setFontSize(10);
        }
        // Paginação
        let textoRodape = 'Página ' + data.pageNumber;
        if (typeof doc.putTotalPages === 'function') {
          textoRodape = textoRodape + ' de ' + totalPagesExp;
        }
        doc.text(textoRodape, larguraPagina - margem - 100, alturaPagina - 10);
      }
    }));
  }
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }
  return doc;
};
const exportarFrontend = (columns, rows, estilo) => {
  const doc = gerarDocJSPDF(columns, rows, estilo, true);
  const pdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(pdfBlob);
  return pdfUrl;
};
const exportarBackend = htmlString => {
  const dom = new JSDOM(htmlString);
  const [columns, rows] = tableToJson(dom);
  const doc = gerarDocJSPDF(columns, rows, null, false);
  return doc.output('arraybuffer');
};
export default {
  exportarFrontend,
  exportarBackend
};