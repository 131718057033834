import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        maxWidth: '100vw',
        //overflowY: 'hidden',
    },
    mainContentSemTab: {
        display: 'block',
        marginTop: theme.spacing(13),
        padding: theme.spacing(2),
        width: `100vw`,
        minHeight: 'calc(100vh - 104px)',
    },
    mainContentFullscreen: {
        display: 'flex',
        flexGrow: 1,
        marginTop: '104px',
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(6),
        minHeight: 'calc(100vh - 104px)',
    },
    mainContentPadrao: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        marginTop: '104px',
        width: `100vw`,
        padding: theme.spacing(0),
    },
    fakeToolbar: {
        ...theme.mixins.toolbar,
    },
    fullContent: {
        height: '100%',
        width: '100%',
    },
    button: {
        margin: '8px 8px 8px 0px',
    },
    pivottable: {
        overflowY: 'hidden',
        overflowX: 'auto',
        minHeight: '550px',
    },
    dialogContent: {
        '& .MuiDialogContent-root': {
            paddingLeft: '0px',
        },
    },
}));
