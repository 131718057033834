import React from 'react';
import { Funcionalidade } from '@stt-analise/util';
import { SttItCidades, SttItExames, SttItPontos, SttItSolicitacoes, SttMonitoracaoEventos, SttTabelaDados } from '@stt-componentes/icons';

// pages
import Inicio from '../pages/interno/inicio/Inicio';
import TabelaDados from '../pages/interno/tabela-dados/TabelaDados';
import MapaInterativo from '../pages/interno/mapa-interativo/MapaInterativoPage';
import Dashboard from '../pages/interno/dashboard/DashboardPage';
import Agendamento from '../pages/interno/administrativo/Administrativo';

import { Strings } from '@stt-utilitarios/core';
const strings = { ...Strings.analise };

const HOME = {
    id: '1',
    text: strings.Comuns.paginaInicial,
    exact: false,
    path: '/apps/menu-principal',
    semPadding: true,
};

const SUBMODULOS = [
    {
        id: '3',
        text: strings.PaginaInicial.tabelaDeDados,
        exact: true,
        path: '/apps/tabela-de-dados',
        funcionalidade: Funcionalidade.PAGINA_TABELA_DADOS,
        layoutType: 'sem-tab',
    },
    {
        id: '5',
        text: strings.PaginaInicial.monitoracaoEventos,
        exact: true,
        path: '/apps/mapa-interativo',
        funcionalidade: Funcionalidade.PAGINA_MAPA_EVENTOS,
        layoutType: 'fullscreen',
    },
    {
        id: '6',
        text: 'Dashboard',
        exact: true,
        path: '/apps/dashboard',
        funcionalidade: Funcionalidade.PAGINA_DASHBOARD,
    },
    {
        id: '14',
        text: strings.PaginaInicial.administrativo,
        exact: true,
        path: '/apps/administrativo',
        funcionalidade: Funcionalidade.PAGINA_ADMINISTRATIVO_AGENDAMENTO,
        disabledShortcut: true,
    },
];

const IMG_MAP = {
    '/apps/tabela-de-dados': <SttTabelaDados style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%' }} width={160} height={160} />,
    '/apps/dashboard/teleconsultoria': <SttItCidades style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/alofono': <SttItExames style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/alofono-paciente': <SttItSolicitacoes style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/mapa-interativo': <SttMonitoracaoEventos style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/itinerario': <SttItExames style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/itinerario-solicitacoes': <SttItSolicitacoes style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/itinerario-cidades': <SttItCidades style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
    '/apps/dashboard/itinerario-pontos': <SttItPontos style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', fontSize: '160px', color: 'black' }} width={160} height={160} />,
};

const PAGES_MAP = {
    '/apps/menu-principal': Inicio,
    '/apps/tabela-de-dados': TabelaDados,
    '/apps/mapa-interativo': MapaInterativo,
    '/apps/dashboard': Dashboard,
    '/apps/administrativo': Agendamento,
};

export default { HOME, SUBMODULOS, IMG_MAP, PAGES_MAP };
