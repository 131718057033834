import { useEffect, useState, useRef, lazy, Suspense, memo, useCallback, useContext } from 'react';
import { SttCircularProgress, SttTranslateHook } from '@stt-componentes/core';
import { SttTabs } from '@stt-componentes/core-v2';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(1.5),
        // backgroundColor: '#ddd',
        zIndex: 0,
    },
}));

const MapaInterativoGeral = lazy(() => import('./geral/MapaInterativoGeral'));
const MapaInterativoTeledermato = lazy(() => import('./teledermato/MapaInterativoTeledermato'));

const MapaInterativoGeralSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <MapaInterativoGeral {...props} />
        </Suspense>
    );
});

const MapaInterativoTeledermatoSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <MapaInterativoTeledermato {...props} />
        </Suspense>
    );
});

const MapaInterativoPage = ({ user }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [abas, setAbas] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState('0');
    const stateRef = useRef();

    stateRef.current = abas;

    const adicionarAba = useCallback((aba) => {
        let dadosAbas = [...stateRef.current];

        let { idAba } = aba;
        // !some para evitar adição de aba repetida
        if (!dadosAbas.some((a) => a.idAba === idAba)) {
            dadosAbas.push(aba);
            setAbas(dadosAbas);
        }

        setTimeout(() => {
            setAbaAtiva(dadosAbas.findIndex((a) => a.idAba === idAba).toString());
        }, 120);
    }, []);

    const removerAba = (indice) => {
        setAbaAtiva('0');
        const i = indice[0];

        const dadosAbas = [...stateRef.current.slice(0, i), ...stateRef.current.slice(i + 1)];

        setTimeout(() => {
            setAbas(dadosAbas);
        }, 120);
    };

    useEffect(() => {
        let dadosAbas = [
            {
                titulo: 'Geral', //strings.Administrativo.abaMapaInterativoGerals,
                conteudo: MapaInterativoGeralSuspense,
                key: 'Geral',
                permanente: true,
                adicionarAba,
            },
            {
                titulo: 'Teledermatologia',
                conteudo: MapaInterativoTeledermatoSuspense,
                key: 'Teledermatologia',
                permanente: true,
                adicionarAba,
            },
        ];

        setAbas(dadosAbas);
    }, [user]);

    return (
        <SttTabs
            abas={abas}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={setAbaAtiva}
            abaAtiva={abaAtiva}
            handleCloseTab={(indice) => removerAba(indice)}
            permanente={true}
            preload={false}
        />
    );
};

export default MapaInterativoPage;
